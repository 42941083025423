import React from "react";
import Layout from "../../components/layout";
import { Container, H1, PrevStep, StepIndicator } from "../../styles/ui";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Contact from "../../components/contact";
import Card from "../../components/card";

import { FiCheckCircle } from "react-icons/fi";

const Eredmenyessegmeres: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="marketing"
      title="Eredményességmérés"
      description="Eredményességmérés"
    >
      <Wrapper>
        <Inner>
          <div
            css={css`
              grid-area: txt;
              h3 {
                margin: 32px 0;
              }
            `}
          >
            <StepIndicator step={4} />
            <H1>
              Eredményesség
              <wbr />
              mérés
            </H1>
            <p>
              Sikerességünk szempontjából mindenképp ismernünk kell, hogy a
              stratégiánkhoz kapcsolódó taktikai (megvalósítási) eszközök milyen
              hatékonysággal kerültek bevetésre. Szolgáltatásaink utolsó eleme
              ezért is az eredményességmérés, hisz az állandóan változó piaci
              környezetben semmit sem tekinthetünk állandónak, így a kidolgozott
              stratégiát és az ahhoz tartozó eszközök hatásait mindenképp
              különböző mérőszámokkal tartjuk célszerűnek megvizsgálni.
              Szolgáltatás-csomagunk ezen része egyrészt visszajelzést ad
              ügyfeleinknek is az egyes kidolgozott és megvalósított marketing
              és kereskedelmi eszközök hatékonyságáról, másrészt lehetővé teszi
              a folyamatos fejlődés fenntartását is, hisz az újabb és újabb
              mérések lehetővé teszik a taktikai eszközök finomhangolását.
            </p>
          </div>
          <div
            css={css`
              grid-area: card;
            `}
          >
            <Card>
              <h2>Módszerek</h2>
              <ul style={{ padding: 0 }}>
                <Li>Komplex mutatórendszerek bevezetése</Li>
                <Li>Web-analitika, online kommunikáció eredményesség mérése</Li>
                <Li>Kommunikációs tartalmak eredményessége</Li>
              </ul>
            </Card>
          </div>
          <PrevNext
            css={css`
              grid-area: nav;
            `}
          >
            <PrevStep step={3} name={"Megvalósítás"} />
            <div />
          </PrevNext>
        </Inner>
      </Wrapper>
      <Contact variant="marketing" />
    </Layout>
  );
};

export default Eredmenyessegmeres;

interface LiProps {
  children?: React.ReactNode;
}

const Li: React.FC<LiProps> = ({ children }) => (
  <li
    css={css`
      list-style: none;
      display: grid;
      grid-template-columns: 20px 1fr;
      column-gap: 8px;
      margin-bottom: 16px;
      svg {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: var(--color-primary);
        fill: none;
      }
      p {
        display: inline-block;
      }
    `}
  >
    <FiCheckCircle />
    <p>{children}</p>
  </li>
);

const Wrapper = styled(Container)`
  margin: 160px 0 96px 0;
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr 400px;

  grid-template-areas:
    "txt card"
    "nav card";
  column-gap: 32px;
  row-gap: 64px;

  h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  @media (max-width: 1310px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "txt"
      "card"
      "nav";
  }
`;

const PrevNext = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  row-gap: 32px;
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;
